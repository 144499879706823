import React, { Component } from 'react'
import { NamespacesConsumer } from 'react-i18next'

import {
    Container, Row, Col
} from 'react-bootstrap'
import { Link } from '@sckit/gatsby-plugin-i18next'

import LocalImage from './local-image'

import './footer.css'

class Footer extends Component {

    render() {
        return (
            <NamespacesConsumer>
                {(t, { i18n }) => {
                    const lang = i18n.language.toLowerCase();

                    return (


                        <>

                            <footer id="footer">

                                <div className="upper">
                                    <Container className="wrapper">
                                        <Row className="justify-content-center">

                                            <Col xl={4} md={3} className="copyright order-md-2">
                                                <a href="http://www.skronex.com/" target="_blank" rel="noopener ">
                                                    <p className="remark">{t('TEXT000137')}</p>
                                                    <p><LocalImage name="copyright_sk" attr={{ className: "img-fluid", alt: t('TEXT000136') }} /></p>
                                                    <p>{t('TEXT000136')}</p>
                                                </a>
                                            </Col>
                                            <Col md={{ span: 4, offset: 1 }} className="copyright order-md-3">
                                                <a href="http://www.aitherentertainment.com/" target="_blank" rel="noopener ">
                                                    <p className="remark">{t('TEXT000138')}</p>
                                                    <p><LocalImage name={"copyright_aither_" + lang} attr={{ className: "img-fluid", alt: t('TEXT000121') }} /></p>
                                                    <p>{t('TEXT000121')}</p>
                                                </a>
                                            </Col>

                                            <Col xl={4} md={4} xs={10} className="sns text-center order-md-1">
                                                <p className="remark">{t('TEXT000112')}</p>
                                                <div className="d-flex justify-content-between">
                                                    <a href="https://www.facebook.com/acidrainworld/" target="_blank" rel="noopener ">
                                                        <LocalImage name="sns_fb" attr={{ className: "img-fluid", alt: "Facebook" }} />
                                                    </a>
                                                    <a href="https://www.youtube.com/acidrainworld" target="_blank" rel="noopener ">
                                                        <LocalImage name="sns_youtube" attr={{ className: "img-fluid", alt: "Youtube" }} />
                                                    </a>
                                                    <a href="https://www.instagram.com/acidrainworld_official/" target="_blank" rel="noopener ">
                                                        <LocalImage name="sns_instagram" attr={{ className: "img-fluid", alt: "Instagram" }} />
                                                    </a>
                                                    <a href="http://eepurl.com/dvc7tr" target="_blank" rel="noopener ">
                                                        <LocalImage name="sns_mail" attr={{ className: "img-fluid", alt: "Subscribe" }} />
                                                    </a>
                                                    <a href="https://twitter.com/acidrainworld" target="_blank" rel="noopener ">
                                                        <LocalImage name="sns_twitter" attr={{ className: "img-fluid", alt: "Twitter" }} />
                                                    </a>
                                                </div>
                                            </Col>

                                        </Row>
                                    </Container>
                                </div>

                                <div className="base">
                                    <Container className="wrapper">
                                        <Row>
                                            <Col md={7} className="d-flex align-self-center">
                                                {t('PT0901')}
                                            </Col>
                                            <Col md={5} className="links-block">
                                                <Link to="/contact/" target="_blank" rel="noopener ">{t('PT0904')}</Link>|
                                                <Link to="/policy/" target="_blank" rel="noopener ">{t('PT0902')}</Link>|
                                                <Link to="/terms/" target="_blank" rel="noopener ">{t('PT0903')}</Link>
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>

                            </footer>
                        </>
                    )
                }}
            </NamespacesConsumer>
        )
    }
}

export default Footer
