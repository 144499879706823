import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import { withNamespaces } from 'react-i18next'
import CookieConsent from "react-cookie-consent"

import Menu from './top-nav'
import Footer from './footer'

class Layout extends Component {

    constructor(props) {
        super(props);
        // Don't call this.setState() here!
        this.state = {
            // langStyle: 'en'
        }

        this.siteData = this.props.siteData || {}

        // const language = this.props.i18n.language
        // if ( language != "en" ){
        //     this.state.langStyle = 'lang_'+ language
        // }
    }

    render() {
        const t = this.props.t

        return (
            <StaticQuery
                query={graphql`
                    query SiteTitleQuery {
                        site {
                            siteMetadata {
                                title
                                description
                                keywords
                                siteUrl
                            }
                        }
                        siteOGImage: file(name: {eq: "og_website"}) {
                            publicURL
                        }

                    }
                `}
                render={data => {
                    const ogTitle = (this.siteData !== {}) && (this.siteData.title ? this.siteData.title : t('TEXT000109')) || data.site.siteMetadata.title
                    const ogDesc = (this.siteData !== {}) && (this.siteData.desc ? this.siteData.desc : data.site.siteMetadata.description) || data.site.siteMetadata.description
                    const ogUrl = (this.siteData !== {}) && (this.siteData.url ? this.siteData.url : data.site.siteMetadata.siteUrl) || data.site.siteMetadata.siteUrl
                    const ogImg = (this.siteData !== {}) && (this.siteData.img ? this.siteData.img : data.siteOGImage.publicURL) || ''

                    return (
                    <>
                        <Helmet
                            title={ogTitle}
                            meta={[
                                { name: 'description', content: ogDesc  },
                                { name: 'keywords', content: data.site.siteMetadata.keywords },
                                { property:'og:type', content:'website' },
                                { property:'og:title', content: ogTitle },
                                { property:'og:url', content:ogUrl },
                                { property:'og:description', content:ogDesc },
                                { property:'og:image', content:ogImg },
                            ]}
                        >
                            <html lang="en"/>
                            <link rel="dns-prefetch" href="https://typekit.net"/>
                            <link rel="dns-prefetch" href="https://fonts.gstatic.com"/>
                        </Helmet>

                        <div id="top" className={`lang_${this.props.i18n.language} `}>
                            <Menu />
                            {this.props.children}

                            <CookieConsent
                                location="bottom"
                                buttonText="Got it and Close!"
                                cookieName="ARWBRANDSITECOOKIES"
                                style={{ background: "#000000", color: "#FFFFFF" }}
                                buttonStyle={{ background: "#FF9900", color: "#FFFFFF", fontSize: ".9rem" }}
                                expires={150}
                            >
                                This website uses cookies to ensure you get the best experience on our website.
                            </CookieConsent>
                            <Footer />
                        </div>
                    </>
                )}
            }
            />
        )
    }
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
    siteData: PropTypes.shape({
        title: PropTypes.string
    })
}

export default withNamespaces()(Layout)
