import React, { Component } from 'react'
import {
  Nav
} from 'react-bootstrap'
import { Language } from '@sckit/gatsby-plugin-i18next'
import LocalImage from './local-image'

class LangSwitcher extends Component {

    render() {
        const uid = `nav_lang_${+(new Date())}`
        return (
            <Language>
            {({ changeLng }) => {
                return (
                    <Nav id={uid} className="mr-auto">
                        <Nav.Link className="" href="#" onClick={(e) => {e.preventDefault();changeLng('en')}}>
                            <LocalImage name="lang_en" attr={{className: "icon", alt:"Lang"}} fluid={false} />
                        </Nav.Link>
                        <Nav.Link className="" href="#" onClick={(e) => {e.preventDefault();changeLng('zh-hk')}}>
                            <LocalImage name="lang_hk" attr={{className: "icon", alt:"Lang"}} fluid={false} />
                        </Nav.Link>
                        <Nav.Link className="" href="#" onClick={(e) => {e.preventDefault();changeLng('zh-cn')}}>
                            <LocalImage name="lang_cn" attr={{className: "icon", alt:"Lang"}} fluid={false} />
                        </Nav.Link>
                    </Nav>
                )
            }}
            </Language>
        )
    }

}

export default LangSwitcher
