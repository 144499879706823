import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import _ from 'lodash'

const NormalImg = ({ image, attr }) => {
    return (
        <img src={image.publicURL} {...attr} />
    )
}

const SrcSetImg = ({ image, attr }) => {
    return (
        <Img fluid={image.childImageSharp.fluid} {...attr} />
    )
}

const LocalImage = ({ name, attr, fluid }) => {
    // console.log(attr)
    fluid = fluid || false

    return (
        <StaticQuery
            query = {graphql`
                query GetImages($ext: [String] = ["svg", "png", "jpg", "jpeg", "gif"]) {
                    images: allFile(filter: { extension: { in: $ext } }) {
                        edges {
                            node {
                                extension
                                name
                                absolutePath
                                publicURL
                                childImageSharp {
                                    fluid(srcSetBreakpoints: [320, 640, 1024, 1200, 1920]) {
                                        base64
                                        aspectRatio
                                        srcSet
                                        src
                                        sizes
                                    }
                                }
                            }
                        }
                    }
                }
            `}
            render = {data => {
                const _images = data.images.edges
                // Debug: 
                // console.log(`=== find image ${name} ===`)
                // console.log(_.find(_images, ({node}) => node.name === `${name}`))
                // const image = _.find(_images, ({node}) => node.name === `${name}`).node
                const findImage = _.find(_images, ({node}) => node.name === `${name}`)
                if ( findImage === undefined ) {
                    console.log(`Fail to findImage , name  = ${name}`)
                    return null
                }
                const image = findImage.node
                
                if (image.childImageSharp === null || fluid === false) {
                    // console.log(`NormalImg = ${image.publicURL}`)
                    return <NormalImg image={image} attr={attr} />
                } else {
                    // console.log(`SrcSetImg`)
                    return <SrcSetImg image={image} attr={attr} />
                }
            }}
        />
  )
}

LocalImage.propTypes = {
  name: PropTypes.string.isRequired,
  attr: PropTypes.object,
  fluid: PropTypes.bool
}

export default LocalImage
